<template>
  <div class="wrap">
    <div class="main">
      <div v-html="nr"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { showConfirmDialog, showNotify } from 'vant';
import { mapActions, mapState } from 'vuex';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';

export default {
  name: 'Fwtc',
  data() {
    return {
      nr: ''
    }
  },
  created() {
    this.getData();
  },
  mounted() {

  },
  methods: {
    async getData() {
      let { data: res } = await axios.get('/api/api/get_setting', {
        params: {
          key: 'app_xieyi',
          sjhm: this.$route.query.sjhm
        }
      });
      // console.log(res);
      this.nr = res.data[0].description;
    }
  }

}
</script>
<style lang="less" scoped>
.wrap {

  .main {
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    font-size: 14px;
    color: #333;
    line-height: 1.5;
  }

}</style>
